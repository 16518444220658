import Logo from "../../components/logo/logo";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Email from "@mui/icons-material/Email";
import "./home.css";
import ServicesComponent from "./services";
import UserMenu from "../../components/userMenu";
import { useEffect, useState } from "react";
import EmailModal from "../../components/email/emailModal";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

const Home = () => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("Home page mounted");
    console.log(t("send_email"));
    console.log(t("language"));

  }, [t]);
  return (
    <>
      <div className="home">
        <div
          style={{
            display: "flex",
            maxWidth: "100vw",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px",
            position: "relative"
          }}
        >
          <div className="header">
            <div className="menuLeft">
              <div
                className="buttons"
                onClick={handleOpen}
                title={t("modalEmail.send_email")}
              >
                <Email />
              </div>
              <div className="tel"></div>
            </div>
            <div className="menuRight">
              <LanguageSelector />
              <div
                className="buttons"
                onClick={() => setShowUserMenu(!showUserMenu)}
              >
                <AccountCircleIcon />
              </div>
            </div>
          </div>
          <UserMenu showUserMenu={showUserMenu} setShowUserMenu={setShowUserMenu} />
          <Logo />
        </div>
        <ServicesComponent />
      </div>
      <EmailModal open={open} handleClose={handleClose} />
    </>
  );
};
export default Home;
