import React from "react";
import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./selectors.css";

const LanguageSelector = ({ changeLanguage }) => {
  const { i18n } = useTranslation();

  const languages = [
    {
      code: "en",
      name: "English"
    },
    {
      code: "fr",
      name: "Français"
    }
  ];

  const handleChange = (event) => {
    const newLanguage = event.target.value;
    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
      if (changeLanguage) {
        console.log("Change language to:", newLanguage);
        changeLanguage(newLanguage);
      }
    }
  };

  return (
    <Select
      className="selector"
      value={i18n.language}
      onChange={handleChange}
      variant="outlined"
      displayEmpty
      size="small"
    >
      {languages.map((option) => (
        <MenuItem className="selector-list-item" key={option.code} value={option.code}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSelector;
