
import logoImage from './logo.png';
import './logo.css';
import { useTranslation } from 'react-i18next';
const Logo = () => {

  const { i18n, t } = useTranslation();




  return (
    <div className="big-logo-box">
      <img src={logoImage} alt="logo" className="logo" />
  
    
      <>
     <div className="logo-title">
     Freemen 
   </div>
   <div className="logo-subtitle">
     - Solutions -
   </div>
   </>
    
     
      <div className="description">
        {t("home.description")}
    </div>
    <div className="description">
        {t("home.and")}
    </div>
    <div className="description">
        {t("home.domotique")}
    </div>
    
    </div>
  );
}
export default Logo;