import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Backdrop,
  Fade
} from "@mui/material";
import "./emailModal.css";
import logo from "../logo/logo.png";
import { useTranslation } from "react-i18next";
import emailjs from 'emailjs-com';
import { useSnackbar } from "../../context/snackbarContext";

const EmailModal = ({ open, handleClose }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });

  const { showSnackbar } = useSnackbar();
  
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Use your own emailJS template and user ID
    const templateParams = {
      titre: "Freemen Solutions website request",
      to: "info@freemensolutions.com", // Change to your recipient
      from: form.name, // Change to your verified sender
      subject: form.subject,
      reply: form.email,
      email:form.email,
      html: form.message,
      messageTitle: "Freemen Solutions website request",
    };
    try {

      // Replace 'your_service_id', 'your_template_id', and 'your_user_id' with your actual values
      await emailjs.send('service_xnup0xh', 'template_swnsbaa', templateParams, 't0shc25crJ8WOi5WV');
      
      console.log('Email sent successfully');


      /// message in my snackbar provider
      showSnackbar("Email sent successfully", "success");

      setForm({
        name: "",
        email: "",
        subject: "",
        message: ""
      });
          
    } catch (error) {
      console.error('Error sending email:', error);


      /// message in my snackbar provider
      showSnackbar("Error sending email", "error");


    } finally {
      handleClose(); // Close the modal after submission
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box className="modal-box">
          <div style={{ width: "60%", margin: "auto" }}>
            <img src={logo} alt="logo" width="100%" />
          </div>
          <div className="logo-title-small">Freemen</div>
          <div className="logo-subtitle-small">- Solutions -</div>
          <Typography className="modal-title">
            {t("modalEmail.send_email")}
          </Typography>
          <form onSubmit={handleSubmit} className="modal-form">
            <TextField
              name="name"
              label={t("modalEmail.name")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={form.name}
              onChange={handleChange}
              className="modal-input"
            />
            <TextField
              name="email"
              label={t("modalEmail.email")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={form.email}
              onChange={handleChange}
              className="modal-input"
            />
            <TextField
              name="subject"
              label={t("modalEmail.subject")}
              variant="outlined"
              fullWidth
              margin="normal"
              value={form.subject}
              onChange={handleChange}
              className="modal-input"
            />
            <TextField
              name="message"
              label={t("modalEmail.message")}
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={form.message}
              onChange={handleChange}
              className="modal-input"
            />
            <Button type="submit" variant="contained" className="modal-button">
                {t("modalEmail.send")}
            </Button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EmailModal;
