import React, { useState, useEffect, useRef } from "react";
import "./userMenu.css";
import { TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";
import KeyIcon from '@mui/icons-material/Key';

const UserMenu = ({ showUserMenu, setShowUserMenu }) => {
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowUserMenu(false);
    }
  };

  useEffect(() => {
    if (showUserMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUserMenu]);

  return (
    <div
      ref={menuRef}
      className={`user-menu ${showUserMenu ? "show" : "hide"}`}
    >
      <div className="menu-title">Welcome!</div>
      <div className="user-login">
        <div style={{fontSize:"16px",marginTop:"10px", letterSpacing:"1px", display:"flex", justifyContent:"center", alignItems:"center"}}>
          Client Access <KeyIcon style={{marginLeft:"5px"}} /> </div>
        <TextField
          label="Email"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          className="user-login-input"
        />
        <TextField
          label="Password"
          type="password"
          value={userPassword}
          onChange={(e) => setUserPassword(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          className="user-login-input"
        />
        <div>
            <div style={{textAlign:"center",fontSize:"12px", cursor:"pointer" }}>
                I forgot my password
            </div>
        <Button type="submit" variant="contained" className="user-login-button">
               Login
            </Button>
        </div>

      </div>
    </div>
  );
};

export default UserMenu;
