import React from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';

// Icons from Material UI
import StoreIcon from '@mui/icons-material/Store';
import HouseIcon from '@mui/icons-material/House';
import InventoryIcon from '@mui/icons-material/Inventory';
import BusinessIcon from '@mui/icons-material/Business';
import CryptoIcon from '@mui/icons-material/CurrencyBitcoin';
import PaymentIcon from '@mui/icons-material/Payment';
import AiIcon from '@mui/icons-material/SmartToy';
import HomeAutomationIcon from '@mui/icons-material/Home';

// Import the CSS file
import './services.css';
import { useTranslation } from 'react-i18next';


const ServicesComponent = () => {

    const { t } = useTranslation();

    const services = [
        { icon: <StoreIcon className="icon" />, title: t("badges.badge1.title"), description: t("badges.badge1.description") },
        { icon: <HouseIcon className="icon" />, title: t("badges.badge2.title") , description:t("badges.badge2.description")  },
        { icon: <InventoryIcon className="icon" />, title: t("badges.badge3.title"), description:t("badges.badge3.description") },
        { icon: <BusinessIcon className="icon" />, title: t("badges.badge4.title"), description:t("badges.badge4.description")  },
        { icon: <CryptoIcon className="icon" />, title: t("badges.badge5.title"), description:t("badges.badge5.description") },
        { icon: <PaymentIcon className="icon" />, title: t("badges.badge6.title"), description: t("badges.badge6.description")},
        { icon: <AiIcon className="icon" />, title: t("badges.badge7.title"), description:t("badges.badge7.description")},
        { icon: <HomeAutomationIcon className="icon" />, title: t("badges.badge8.title"), description:t("badges.badge8.description")},
      ];
      
  return (
    <Container className="container">
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper className="paper">
              <Box display="flex" flexDirection="column" alignItems="center">
                {service.icon}
                <Typography variant="h6" align="center" gutterBottom>
                  {service.title}
                </Typography>
                <Typography variant="body1" align="center">
                  {service.description}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicesComponent;
